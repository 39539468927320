<template>
  <div class="-ml-4">
    <trac-loading v-if="isLoadingBeneficiaries" />
    <main class="mx-auto w-11/12" @click="clickOutside" v-else>
      <trac-loading v-if="isLoading" />
      <trac-back-button>Wallet</trac-back-button>
      <trac-modal
        v-if="benefitiaryModalCtrl"
        @close="benefitiaryModalCtrl = false"
        :cancel="!successfulTransaction"
      >
        <trac-beneficiary-details
          @transfer-to-benficiary="currentBeneficiarySelectedIndex = 2"
          v-if="currentBeneficiarySelectedIndex === 1"
          :selectedBeneficiary="currentlySlectedBeneficiary"
        ></trac-beneficiary-details>
        <trac-beneficiary-transfer
          :selectedBeneficiary="currentlySlectedBeneficiary"
          :walletDetails="walletDetails"
          v-if="currentBeneficiarySelectedIndex === 2"
          @transferDetailsEmitter="setupTransferDetails"
        ></trac-beneficiary-transfer>
        <!-- :selectedNetwork="selectedNetwork" -->
        <!-- :transactionData="transactionData" -->
        <trac-transaction-pin
          :walletData="walletDetails"
          @add-transaction-pin="addtransactionPin"
          v-if="currentBeneficiarySelectedIndex === 3"
        ></trac-transaction-pin>
        <div
          class="flex flex-col justify-center items-center p-6 gap-6"
          v-if="0"
        >
          <img src="./../../../assets/svg/wallet/success_check.svg" alt="" />
          <p class="">You have succesfully edited beneficiary account.</p>
        </div>
      </trac-modal>

      <trac-modal v-if="deleteModal" @close="deleteModal = false">
        <div class="mx-10 my-8 text-center">
          <span class="font-semibold text-sm"
            >Are you sure you want to delete
            {{ currentlySlectedBeneficiary.accountname }}?</span
          >
          <div class="flex flex-col gap-2 md:gap-0 md:flex-row w-8/12 justify-between mx-auto mt-8">
            <trac-button variant="outline" @button-clicked="deleteModal = false"
              >Cancel
            </trac-button>
            <trac-button
              class="text-secondaryRed border-secondaryRed"
              variant="outline"
              @button-clicked="deleteBeneficiary(currentlySlectedBeneficiary)"
              >Delete
            </trac-button>
          </div>
        </div>
      </trac-modal>

      <trac-modal
        class=""
        v-if="addBeneficiaryModalCtrl"
        @close="addBeneficiaryModalCtrl = false"
      >
        <div class="p-10">
          <h2 class="mt-6 font-bold">Add Beneficiary Details</h2>
          <!-- <trac-input
          class="mt-6"
          placeholder="Account Holder’s Name"
        ></trac-input> -->
          <trac-dropdown-exteneded
            :neededProperty="'bankname'"
            :options="banks"
            :selector="'Select Bank'"
            class="mt-6"
            @optionSelected="selectBenficiaryBank($event)"
          ></trac-dropdown-exteneded>
          <trac-input
            v-model="newBeneficiary.accountNumber"
            class="mt-6"
            placeholder="Account Number"
          ></trac-input>
          <div class="flex justify-end mt-8">
            <trac-button @button-clicked="createBeneficiary">SAVE</trac-button>
          </div>
        </div>
      </trac-modal>

      <div class="">
        <!-- <trac-back-button>Back</trac-back-button> -->
        <div class="flex justify-between items-center my-6">
          <h2 class="">All Beneficiaries</h2>
          <trac-button class="" @button-clicked="openAddBeneficiaryModal"
            >Add Beneficiary</trac-button
          >
        </div>
        <div class="rounded-md shadow-md p-4">
          <div class="my-4">
            <trac-input
              placeholder="Search account name or number"
              class="md:w-2/5"
              v-model="searchedBeneficiary"
            ></trac-input>
          </div>
          <div class="md:hidden">
            <div
              v-for="(beneficiary, i) in allFilteredBeneficiaries"
              :key="i"
              class="flex py-3"
            >
              <div
              @click="showBeneficiaryDetails(beneficiary)"
                class="
                  smallest
                  h-8
                  w-8
                  flex
                  justify-center
                  items-center
                  rounded-full
                  mr-4
                  bg-accentLight
                "
              >
                <div class="tiny text-accentDark cursor-pointer uppercase">
                  {{
                    beneficiary.accountname
                      .split(" ")
                      .map((name) => name.substring(0, 1))
                      .join("")
                  }}
                </div>
              </div>
              <div class="text-xs text-gray-600 flex-1" @click="showBeneficiaryDetails(beneficiary)">
                <p class="font-bold mt-1">{{ beneficiary.accountname }}</p>
                <p class="">
                  {{ beneficiary.accountnumber }} | {{ beneficiary.bankname }}
                </p>
              </div>
              <div class="flex relative">
                <img
                  @click="openBeneficiaryOptions(beneficiary, i)"
                  src="./../../../assets/svg/wallet/more_vert.svg"
                  alt=""
                  class="beneficiary-options"
                />
                <div
                      v-if="
                        i === currentSelectedBenficiaryOptions && optionsOpened
                      "
                      class="
                        absolute
                        bg-white
                        shadow-md
                        p-2
                        rounded-md
                        beneficiary-options
                      "
                      style="right: 70%; top: 30%"
                    >
                    <p
                        @click="deleteModal = true"
                        class="
                          text-sm
                          p-1
                          hover:bg-veryLight
                          m-1
                          text-red-600
                          beneficiary-options
                        "
                      >
                        Delete
                      </p>
                    </div>
              </div>
            </div>
            <div
              v-if="allFilteredBeneficiaries.length === 0"
              class="flex justify-center items-center h-60 text-sm"
            >
              No beneficiaries added yet.
            </div>
          </div>

          <div
            class="overflow-x-visible hidden md:block"
            v-if="allBeneficiaries"
          >
            <table class="w-full">
              <thead>
                <tr class="bg-accentLight">
                  <th class="whitespace-no-wrap p-4 text-xs text-left">Name</th>
                  <th class="whitespace-no-wrap p-4 text-xs text-left">
                    Account Number
                  </th>
                  <th class="whitespace-no-wrap p-4 text-xs text-left">
                    Bank Name
                  </th>
                  <!-- <th class="whitespace-no-wrap p-4 text-xs text-left">
                    Amount Sent
                  </th> -->
                  <th class="whitespace-no-wrap py-4 px-8 text-xs text-left">
                    Action
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="allBeneficiaries.length > 0">
                <tr
                  v-for="(beneficiary, i) in allFilteredBeneficiaries"
                  :key="i"
                  class="hover:bg-transparentBackground cursor-pointer"
                >
                  <td
                    @click="showBeneficiaryDetails(beneficiary)"
                    class="whitespace-no-wrap p-4 text-xs"
                  >
                    {{ beneficiary.accountname }}
                  </td>
                  <td
                    @click="showBeneficiaryDetails(beneficiary)"
                    class="whitespace-no-wrap p-4 text-xs"
                  >
                    {{ beneficiary.accountnumber }}
                  </td>
                  <td
                    @click="showBeneficiaryDetails(beneficiary)"
                    class="whitespace-no-wrap p-4 text-xs"
                  >
                    {{ beneficiary.bankname }}
                  </td>
                  <!-- <td
                    @click="showBeneficiaryDetails(beneficiary)"
                    class="whitespace-no-wrap p-4 text-xs"
                  >
                    ----
                  </td> -->
                  <td class="whitespace-no-wrap p-4 text-xs">
                    <trac-button
                      class="ml-4"
                      variant="icon"
                      @button-clicked="transferToBeneficiary(beneficiary)"
                    >
                      Send Money
                    </trac-button>
                  </td>
                  <td class="relative">
                    <img
                      @click="openBeneficiaryOptions(beneficiary, i)"
                      src="./../../../assets/svg/wallet/more_vert.svg"
                      alt=""
                      class="beneficiary-options"
                    />
                    <div
                      v-if="
                        i === currentSelectedBenficiaryOptions && optionsOpened
                      "
                      class="
                        absolute
                        bg-white
                        shadow-md
                        p-2
                        rounded-md
                        beneficiary-options
                      "
                      style="left: 70%; top: 30%"
                    >
                      <!-- <p
                        @click="editBeneficiary(beneficiary)"
                        class="
                          text-sm
                          p-1
                          hover:bg-veryLight
                          m-1
                          text-gray-800
                          beneficiary-options
                        "
                      >
                        Edit
                      </p>
                      <hr /> -->
                      <p
                        @click="deleteModal = true"
                        class="
                          text-sm
                          p-1
                          hover:bg-veryLight
                          m-1
                          text-red-600
                          beneficiary-options
                        "
                      >
                        Delete
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5" class="">
                    <div class="flex justify-center items-center h-60 text-sm">
                      No beneficiaries added yet.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BeneficiaryDetailsVue from "./beneficiaries-components/BeneficiaryDetails.vue";
import MakeTransferVue from "./beneficiaries-components/MakeTransfer.vue";
import TransactionPin from "./../wallet-components/airtime-transaction/TransactionPin.vue";

import { eventBus } from "./../../../main";
import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";

export default {
  components: {
    "trac-beneficiary-details": BeneficiaryDetailsVue,
    "trac-beneficiary-transfer": MakeTransferVue,
    "trac-transaction-pin": TransactionPin,
  },
  data() {
    return {
      isLoading: false,
      isLoadingBeneficiaries: false,
      benefitiaryModalCtrl: false,
      deleteModal: false,
      addBeneficiaryModalCtrl: false,
      successfulTransaction: false,
      currentSelectedBenficiaryOptions: null,
      currentBeneficiarySelectedIndex: null,
      currentlySlectedBeneficiary: null,
      optionsOpened: false,
      allBeneficiaries: null,
      transferDetail: null,
      walletDetails: null,
      searchedBeneficiary: "",
      newBeneficiary: {
        accountNumber: "",
        bankSelected: "",
        bankcode: "",
      },
      banks: [],
    };
  },
  async created() {
    this.isLoadingBeneficiaries = true;
    await this.fetchWalletDetails();
    await this.fetchAllBeneficiariesBanks();
    await this.fetchAllBeneficiaries();
    this.isLoadingBeneficiaries = false;
  },
  computed: {
    allFilteredBeneficiaries() {
      return this.allBeneficiaries.filter(
        (beneficiary) =>
          beneficiary.accountname
            .toLowerCase()
            .includes(this.searchedBeneficiary.toLowerCase()) ||
          beneficiary.bankname
            .toLowerCase()
            .includes(this.searchedBeneficiary.toLowerCase()) ||
          beneficiary.accountnumber.includes(this.searchedBeneficiary)
      );
    },
  },
  methods: {
    openBeneficiaryOptions(benficiary, i) {
      this.currentlySlectedBeneficiary = benficiary;
      this.currentSelectedBenficiaryOptions = i;
      this.optionsOpened = true;
    },
    showBeneficiaryDetails(beneficiary, i) {
      this.benefitiaryModalCtrl = true;
      this.currentlySlectedBeneficiary = beneficiary;
      this.currentBeneficiarySelectedIndex = 1;
    },
    transferToBeneficiary(beneficiary) {
      if (!this.walletDetails) {
        eventBus.$emit("trac-alert", {
          message: "Wallet details hasn't loaded yet. Please refresh.",
        });

        return;
      }

      this.benefitiaryModalCtrl = true;
      this.currentlySlectedBeneficiary = beneficiary;
      this.currentBeneficiarySelectedIndex = 2;
    },
    editBeneficiary(beneficiary) {
      this.addBeneficiaryModalCtrl = true;
    },
    clickOutside(e) {
      if (!e.target.classList.contains("beneficiary-options")) {
        this.optionsOpened = false;
      }
    },
    setupTransferDetails(transferDetail) {
      this.currentBeneficiarySelectedIndex = 3;
      this.transferDetail = transferDetail;
    },
    async addtransactionPin(transactionPinData) {
      this.isLoading = true;
      // this.transactionPinData = transactionPinData;
      // console.log(this.transactionPinData);
      const res = await this.$store.dispatch("VERIFY_USER_PIN", {
        ...transactionPinData,
      });

      if (res.status) {
        this.makeTransferToBeneficiary(this.transferDetail);
        // if (this.transactionData.transactionType === "airtime") {
        //   await this.makeAirtimeRequest();
        // } else {
        //   this.makeDataRequest();
        // }
      } else {
        this.isLoading = false;

        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Invalid pin",
        });
      }
    },
    async makeTransferToBeneficiary(transferDetails) {
      this.isLoading = true;
      const beneficiaryTransferRes = await this.$store.dispatch(
        "TRANSFER_TO_BENEFICIARY",
        {
          transferDetails,
          wallet: this.walletDetails,
        }
      );

      if (beneficiaryTransferRes.status) {
        this.benefitiaryModalCtrl = false;
        eventBus.$emit("trac-alert", {
          message:
            beneficiaryTransferRes.message || "Funds transferred successfully.",
        });
      } else {
        eventBus.$emit("trac-alert", {
          message:
            beneficiaryTransferRes.message || "Error: Couldn't make transfer.",
        });
      }
      this.isLoading = false;
    },
    async fetchWalletDetails() {
      // this.walletDetails = this.$store.getters["GET_WALLET_DETAILS"] || {})[
      //   "data"
      // ];

      if (!this.walletDetails) {
        await this.$store.dispatch("FETCH_WALLET_DETAILS");
        this.walletDetails = (this.$store.getters["GET_WALLET_DETAILS"] || {})[
          "data"
        ];
      }
    },
    selectBenficiaryBank(bankDetails) {
      this.newBeneficiary.bankcode = bankDetails.bankcode;
    },
    async createBeneficiary() {
      this.isLoading = true;
      const bankDetailsRes = await this.resolveBankAccount();

      if (bankDetailsRes) {
        await this.sendBeneficiaryDetails(bankDetailsRes);
      }
      this.isLoading = false;
    },
    async sendBeneficiaryDetails(bankDetailsRes) {
      const payload = {
        accountnumber: this.newBeneficiary.accountNumber,
        accountname: bankDetailsRes.data.accountname,
        kyc: "",
        bvn: "",
        bankcode: this.newBeneficiary.bankcode,
        isdefault: false,
        userid: GET_USER_BUSINESS_ID(),
      };

      const createdBeneficiaryRes = await this.$store.dispatch(
        "CREATE_BENEFICIARIES",
        payload
      );

      if (createdBeneficiaryRes.status) {
        this.addBeneficiaryModalCtrl = false;
        this.newBeneficiary.accountNumber = "";

        // Update list
        const beneficiariesRes = this.$store.getters["GET_ALL_BENEFICIARIES"];
        this.allBeneficiaries = beneficiariesRes.data.map((beneficiary) => {
          const bankName = this.banks.find(
            (bank) => bank.bankcode === beneficiary.bankcode
          );

          return { ...beneficiary, bankname: (bankName || {}).bankname };
        });

        eventBus.$emit("trac-alert", {
          message: "Beneficiary created successfully.",
        });
      } else {
        // Alert error.
        eventBus.$emit("trac-alert", {
          message:
            createdBeneficiaryRes.message ||
            "Error: Couldn't create beneficiary.",
        });
      }
    },
    async deleteBeneficiary(beneficiary) {
      this.isLoading = true;
      const payload = {
        benficiaryID: beneficiary.beneficiaryGuid,
        userid: GET_USER_BUSINESS_ID(),
      };

      const deleteBeneficiaryRes = await this.$store.dispatch(
        "DELETE_BENEFICIARIES",
        payload
      );

      if (deleteBeneficiaryRes.status) {
        this.addBeneficiaryModalCtrl = false;
        this.optionsOpened = false;
        this.deleteModal = false;

        // Update list
        const beneficiariesRes = this.$store.getters["GET_ALL_BENEFICIARIES"];
        this.allBeneficiaries = beneficiariesRes.data;
        this.currentSelectedBenficiaryOptions = null;

        eventBus.$emit("trac-alert", {
          message: "Beneficiary deleted successfully.",
        });
      } else {
        // Alert error.
        eventBus.$emit("trac-alert", {
          message:
            deleteBeneficiaryRes.message ||
            "Error: Couldn't create beneficiary.",
        });
      }
      this.isLoading = false;
    },
    async resolveBankAccount() {
      const payload = {
        accountnumber: this.newBeneficiary.accountNumber,
        bankcode: this.newBeneficiary.bankcode,
      };

      const resolvedBankData = await this.$store.dispatch(
        "FETCH_BENFICIARY_BANK_DETAILS",
        payload
      );

      if (resolvedBankData.status) {
        return resolvedBankData;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: resolvedBankData.message });
      }
    },
    async openAddBeneficiaryModal() {
      this.isLoading = true;
      this.addBeneficiaryModalCtrl = true;
      // const beneficiariesBanksRes = await this.fetchAllBeneficiariesBanks();
      this.isLoading = false;
    },
    async fetchAllBeneficiaries() {
      await this.$store.dispatch("FETCH_ALL_BENEFICIARIES");
      const beneficiariesRes = this.$store.getters["GET_ALL_BENEFICIARIES"];

      if (beneficiariesRes.status) {
        this.allBeneficiaries = beneficiariesRes.data.map((beneficiary) => {
          const bankName = this.banks.find(
            (bank) => bank.bankcode === beneficiary.bankcode
          );

          return { ...beneficiary, bankname: (bankName || {}).bankname };
        });
      }
    },
    async fetchAllBeneficiariesBanks() {
      await this.$store.dispatch("FETCH_ALL_BENEFICIARIES_BANKS");
      const beneficiariesBanksRes =
        this.$store.getters["GET_ALL_BENFICIARY_BANKS"];

      if (beneficiariesBanksRes.status) {
        this.banks = beneficiariesBanksRes.data.sort((a, b) =>
          a.bankname > b.bankname ? 1 : -1
        );
      } else {
        this.addBeneficiaryModalCtrl = false;
        // Alert error.
        eventBus.$emit("trac-alert", {
          message:
            beneficiariesBanksRes.message || "Error: Couldn't load banks",
        });
      }
      // return beneficiariesBanksRes;
    },
  },
};
</script>

<style>
</style>