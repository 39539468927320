<template>
  <main class="py-10 px-6">
    <h2 class="font-semibold text-xs my-0">Make Transfer</h2>
    <h3 class="font-extrabold text-sm mt-4">To Beneficiary</h3>
    <div class="flex flex-col md:flex-row gap-2 justify-between md:items-end mt-8">
      <div class="flex items-center">
        <div
          class="
            smallest
            h-12
            w-12
            flex
            justify-center
            items-center
            rounded-full
            mr-4
            bg-accentLight
          "
        >
          <p class="text-accentDark font-thin text-lg cursor-pointer uppercase">
            {{
              selectedBeneficiary.accountname
                .split(" ")
                .map((name) => name.substring(0, 1))
                .join("")
            }}
          </p>
        </div>
        <div class="">
          <p class="text-xs font-bold">{{ selectedBeneficiary.accountname }}</p>
          <p class="text-xs font-light text-gray-600">
            {{ selectedBeneficiary.accountnumber }} |
            {{ selectedBeneficiary.bankname }}
          </p>
        </div>
      </div>
      <div class="mt-2 md:mt-0">
        <p class="smallest text-gray-600">Transfer from Wallet</p>
        <h2 class="font-extrabold text-xl">
          {{ walletDetails.amount | formatPrice }}
        </h2>
      </div>
    </div>
    <div class="md:w-10/12 mt-8 grid gap-8">
      <trac-input
        placeholder="Amount to transfer"
        v-model="transferDetails.amount"
        type="number"
      ></trac-input>
      <trac-input
        placeholder="Add description  (optional)"
        v-model="transferDetails.narration"
      ></trac-input>
      <div class="flex justify-end">
        <trac-button @button-clicked="emitTransferData" class="w-5/12" :disabled="unableToTransfer"
          >PROCEED</trac-button
        >
      </div>
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_DATA } from '../../../../browser-db-config/localStorage';
export default {
  data() {
    return {
      transferDetails: {
        amount: "",
        bankcode: "",
        bankname: "",
        craccount: "",
        craccountname: "",
        draccountname: "",
        narration: "",
      },
    };
  },
  props: {
    selectedBeneficiary: {},
    walletDetails: {},
  },
  computed: {
    unableToTransfer() {
      return this.walletDetails.amount === 0 || this.transferDetails.amount.trim() === '';
    },
  },
  created() {
  },
  methods: {
    emitTransferData() {
      const payload = {
        amount: this.transferDetails.amount,
        bankcode: this.selectedBeneficiary.bankcode,
        bankname: this.selectedBeneficiary.bankname,
        craccount: this.selectedBeneficiary.accountnumber,
        craccountname: this.selectedBeneficiary.accountname,
        draccountname: GET_USER_BUSINESS_DATA().name,
        narration: this.transferDetails.narration,
      };
      this.$emit("transferDetailsEmitter", payload);
    },
  },
};
</script>

<style>
</style>