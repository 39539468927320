<template>
  <main>
    <h3 class="my-6 font-bold text-xs">Review Details</h3>
    <div class="my-6 p-4 shadow-md rounded-md grid grid-cols-2 gap-6">
      <h3 class="font-bold text-sm">Beneficiary Details</h3>
      <div class=""></div>
      <div class="">
        <p class="text-xs text-gray-600">Account Holder Name</p>
        <h2 class="font-bold text-sm">{{ selectedBeneficiary.accountname }}</h2>
      </div>
      <div class="">
        <p class="smallest pl-10 font-light text-gray-600">
          Bank Name
        </p>
        <p class="text-sm pl-10 text-gray-600">{{ selectedBeneficiary.bankname }}</p>
      </div>
      <div class="">
        <p class="text-xs text-gray-600">Account Number</p>
        <h2 class="font-bold text-sm">{{ selectedBeneficiary.accountnumber }}</h2>
      </div>
    </div>
    <div class="flex justify-end mt-10">
      <!-- <trac-button class="text-secondaryRed border-secondaryRed"
              variant="outline">
        Delete
      </trac-button> -->
      <trac-button
        @button-clicked="$emit('transfer-to-benficiary')"
        class="ml-4 font-bold"
      >
        Transfer Now
      </trac-button>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    selectedBeneficiary: {
    }
  },
  created() {
  }
};
</script>

<style>
</style>